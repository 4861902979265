import { useEffect, useState } from "react";
import { Box,CircularProgress } from '@mui/material';
import { ErrorHeader } from "../../shared/errorHeader";
import { AthleteInfo} from "../../api/models/athlete";
import { useParams } from "react-router-dom";
import { getAthlete, getAthleteProfileImage } from "../../api/athleteApi";


export function AthletePage() {

    const [athlete, setAthlete] = useState<AthleteInfo | undefined>(undefined);
    const [memberProfileImage, setMemberProfileImage] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const params = useParams();


    useEffect(() => {

        initPage();
    }, []);

    const initPage = async () => {
        try {

            setIsLoading(true);
            setErrorMessage('');

            if (!params.athleteGuid) {
                throw Error('Athlete GUID is missing');
            }

            await refreshMember(params.athleteGuid);
            await refreshMemberProfileImage(params.athleteGuid);
            setIsLoading(false);


        } catch (error: any) {
            if (error.response?.data) {
                setErrorMessage(error.response.data.message);
            }
            else {
                setErrorMessage(error.message);
            }
            setIsLoading(false);
        }

    }

    async function refreshMember(athleteGuid: string) {
        const response = await getAthlete(athleteGuid)
        setAthlete(response);
    }

    async function refreshMemberProfileImage(athleteGuid: string) {
        const imageResponse = await getAthleteProfileImage(athleteGuid);
        setMemberProfileImage(imageResponse.image);
    }



    const mainContent = () => {

        return (
            
                <Box>
                    <h1>{athlete?.firstName}  {athlete?.lastName} - ({athlete?.role})</h1>
                    <br />
                    {memberProfileImage && <img src={memberProfileImage} height={200} alt='profile' />}
                    <br/><br/>
                    <h1>Stats</h1>
                    <p>height:</p>
                    <p>weight:</p>
                    <h1>Experience</h1>
                    <br/><br/>
                    <h1>Transcripts</h1>
                    <br/><br/>
                   

                    <br />

                </Box>
        );
    }

    return (<Box>
        <ErrorHeader errorMessage={errorMessage} />
        {isLoading && <CircularProgress />}
        {athlete && mainContent()}
    </Box>
    );

}